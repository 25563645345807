import styled from "styled-components";

// Create a styled img component
const HeadImg = styled.img`
  width: 100px;
  display: block;
  margin: auto;
  padding: 10px 0px;

  @media screen and (min-width: 768px){
    width: 200px;
  }
`;

export default function Headline() {
  return (
    <>
      <HeadImg src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/b206ee7a-e094-460a-cb98-c20febfe4a00/public" />
    </>
  );
}
